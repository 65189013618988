// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 樓層平面圖容器樣式 */
.floor-plan-container {
    position: relative;
}

/* 房間樣式 */
.room {
    position: absolute;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
}

.room_success {
    border: 1px solid #5dda00;
    background-color: rgb(21 255 0 / 80%);
}

.room_warning {
    border: 1px solid #c10000;
    background-color: rgb(255 0 0 / 80%);
}

/* Tooltip 樣式 */
.tooltip {
    position: absolute;
    background-color: #333;
    color: #333;
    padding: 1px;
    border-radius: 4px;
    font-size: 12px;
    transform: translate(-50%, -100%);
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease, visibility 0s 0.2s;
}

.room.show-tooltip .tooltip {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s ease, visibility 0s;
    background-color: rgba(255, 255, 255, 0);
}

.tooltip-inner {
    color: #333;
    background-color: #fff;
}

.warn {
    color: #c10000;
}`, "",{"version":3,"sources":["webpack://./src/styles/FloorMap.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,kBAAkB;AACtB;;AAEA,SAAS;AACT;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,qCAAqC;AACzC;;AAEA;IACI,yBAAyB;IACzB,oCAAoC;AACxC;;AAEA,eAAe;AACf;IACI,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;IACjC,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;IACV,iDAAiD;AACrD;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,4CAA4C;IAC5C,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* 樓層平面圖容器樣式 */\n.floor-plan-container {\n    position: relative;\n}\n\n/* 房間樣式 */\n.room {\n    position: absolute;\n    padding: 5px;\n    border-radius: 6px;\n    cursor: pointer;\n}\n\n.room_success {\n    border: 1px solid #5dda00;\n    background-color: rgb(21 255 0 / 80%);\n}\n\n.room_warning {\n    border: 1px solid #c10000;\n    background-color: rgb(255 0 0 / 80%);\n}\n\n/* Tooltip 樣式 */\n.tooltip {\n    position: absolute;\n    background-color: #333;\n    color: #333;\n    padding: 1px;\n    border-radius: 4px;\n    font-size: 12px;\n    transform: translate(-50%, -100%);\n    white-space: nowrap;\n    visibility: hidden;\n    opacity: 0;\n    transition: opacity 0.2s ease, visibility 0s 0.2s;\n}\n\n.room.show-tooltip .tooltip {\n    visibility: visible;\n    opacity: 1;\n    transition: opacity 0.2s ease, visibility 0s;\n    background-color: rgba(255, 255, 255, 0);\n}\n\n.tooltip-inner {\n    color: #333;\n    background-color: #fff;\n}\n\n.warn {\n    color: #c10000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
