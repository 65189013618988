import React, { useState } from 'react';
import useFetchRoomData from '../hooks/useFetchRoomData';
import Room from './Room';

// 假設平面圖位於 /assets 資料夾中
const floorPlans = {
    1: '/epb_home_1f.jpg',
    2: '/epb_home_2f.jpg',
    3: '/epb_home_3f.jpg',
    4: '/epb_home_4f.jpg',
};

const FloorPlanSelector = ({floor}) => {
    const { rooms } = useFetchRoomData(floor);
    const [activeRoom, setActiveRoom] = useState(null);

    const handleRoomClick = (roomId) => {
        setActiveRoom((prevActiveRoom) => (prevActiveRoom === roomId ? null : roomId));
    };

    return (
        <div className="content">
            <div className={`floor-plan-container floor-${floor}`}>
                <img
                src={floorPlans[floor]}
                alt={`Floor ${floor} Plan`}
                style={{ width: '100%' }}
                />
                {rooms?.Rooms?.map((room) => (
                    <div 
                        key={room.id}
                        className={`room ${activeRoom === room.id ? 'show-tooltip' : ''} ${room.is_temp_warning || room.is_humidity_warning ? "room_warning" : "room_success" }`}
                        onClick={() => handleRoomClick(room.id)}
                        style={{ top: `${room.y}%`, left: `${room.x}%` }}
                    >
                        <div className="tooltip">
                            <Room key={room.id} {...room} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FloorPlanSelector;
