import { useState, useEffect } from "react";
import { fetchRoomData } from "../services/api";

const useFetchRoomData = (floor) => {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getRoomData = async () => {
            try {
                const data = await fetchRoomData(floor);
                setRooms(data);
            } catch (error) {
                console.error("Error fetching room data:", error);
            } finally {
                setLoading(false);
            }
        };

        getRoomData();
        const interval = setInterval(getRoomData, 5000); // 每5秒獲取一次數據
        return () => clearInterval(interval);
    }, [floor]);

    return { rooms, loading };
};

export default useFetchRoomData;
