// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history_wrapper {
    width: 1200px;
}

.search_wrapper {
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;
}

.search_wrapper > div {
    margin: 10px 10px 0 0;
}

.search_wrapper input, .search_wrapper select {
    height: 35px;
    border-radius: 4px;
    border: 1px solid #333;
}

.search_wrapper select {
    width: 70px;
}`, "",{"version":3,"sources":["webpack://./src/styles/History.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".history_wrapper {\n    width: 1200px;\n}\n\n.search_wrapper {\n    display: flex;\n    margin-top: 15px;\n    flex-wrap: wrap;\n}\n\n.search_wrapper > div {\n    margin: 10px 10px 0 0;\n}\n\n.search_wrapper input, .search_wrapper select {\n    height: 35px;\n    border-radius: 4px;\n    border: 1px solid #333;\n}\n\n.search_wrapper select {\n    width: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
