import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import FloorSelector from "./components/FloorSelector";
import History from "./components/History";
import "./styles/style.css";

const App = () => {
    return (
        <BrowserRouter>
            <div className="App">
                <div className="header_wrapper">
                    <div className="header">
                        <img src="/taichung_epb.png" alt="臺中市環境保護局環" style={{ width: '170px' }} />
                        <nav className="navigation">
                            <Link to="/" className="btn btn-primary">首頁</Link>
                            <Link to="/history" className="btn btn-secondary">歷史紀錄</Link>
                        </nav>
                    </div>
                </div>
                <div className="wrapper">
                    <Routes>
                        <Route path="/" element={<FloorSelector />} />  {/* 預設首頁為 FloorSelector */}
                        <Route path="/history" element={<History />} />  {/* 歷史紀錄頁面 */}
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
};

export default App;