import { useState, useEffect } from "react";
import { fetchHistoryData } from "../services/api";

const useFetchHistoryData = (floor, mac, startTime, endTime) => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getHistoryData = async () => {
            try {
                const data = await fetchHistoryData(floor, mac, startTime, endTime);
                setHistory(data);
            } catch (error) {
                console.error("Error fetching room data:", error);
            } finally {
                setLoading(false);
            }
        };

        getHistoryData();
        // const interval = setInterval(getHistoryData, 5000); // 每5秒獲取一次數據
        // return () => clearInterval(interval);
    }, [floor, mac, startTime, endTime]);

    return { history, loading };
};

export default useFetchHistoryData;
