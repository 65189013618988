import React from 'react';
import { Dropdown } from 'react-bootstrap';

const BootstrapDropdown = ({ options, selected, onSelect }) => {
    const selectedOption = options.find((option) => option.value === selected);
    return (
        <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
            {selectedOption ? selectedOption.label : '請選擇'}
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {options.map((option, index) => (
            <Dropdown.Item 
                key={index} 
                eventKey={option.value} 
                active={option.value === selected}
            >
                {option.label}
            </Dropdown.Item>
            ))}
        </Dropdown.Menu>
        </Dropdown>
    );
};

export default BootstrapDropdown;
