import React from "react";
import "../styles/style.css";

const Room = ({ id, name, t, h, date, is_temp_warning, is_humidity_warning }) => (
    <div className="room-list__item">
        <h3>{name}</h3>
        <div className="room-list__details">
            <div className="temperature-wrapper">
                <label className="temperature-label">
                    <img className="temperature-img" alt="溫度" src="temperature.png" />
                </label>
                <span className={`temperature-value ${is_temp_warning ? "warn" : ""}`}>{t}</span>
                <span className="temperature-unit">°C</span>
            </div>
            <div className="humidity-wrapper">
                <label className="humidity-label">
                    <img className="humidity-img " alt="濕度" src="humidity.png" />
                </label>
                <span className={`humidity-value ${is_humidity_warning ? "warn" : ""}`}>{h}</span>
                <span className="humidity-unit">％RH</span>
            </div>
            <div className="datetime-wrapper">
                <label className="datetime-label">
                    <i className="fas fa-clock"></i>
                </label>
                <span className="datetime-value">{new Date(date).toLocaleString()}</span>
            </div>
        </div>
    </div>
);

export default Room;
