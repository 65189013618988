import React from 'react';
import useFetchRoomData from '../hooks/useFetchRoomData';
import Room from "./Room";

const RoomList = ({floor}) => {
    const { rooms, loading } = useFetchRoomData(floor);

    return (
    <div className="content">
        {loading ? (
        <p>Loading...</p>
        ) : (
        <div className="room-list__container_wrapper">
            <div className="room-list__warning" hidden={rooms?.WarnRooms?.length > 0 ? false : true}>
                <div className="room-list__container">
                    {rooms?.WarnRooms?.map((room) => (
                        <Room key={room.id} {...room} />
                    ))}
                </div>
            </div>
            <div className="room-list__container">
                {rooms?.Rooms?.map((room) => (
                    <Room key={room.id} {...room} />
                ))}
            </div>
        </div>
        )}
    </div>
    );
}

export default RoomList;
