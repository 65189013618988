export const fetchRoomData = async (floor) => {
    const url = `${process.env.REACT_APP_API_URL}/api/room/list?floor=${floor}`;
    // const url = `http://127.0.0.1:8017/api/room/list?floor=${floor}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch room data");
    }
    const data = await response.json();
    return data;
};

export const fetchHistoryData = async (floor, mac, startTime, endTime) => {
    const params = { floor, mac, startTime, endTime };
    const queryString = toQueryString(params);
    const url = `${process.env.REACT_APP_API_URL}/api/history/list${queryString}`;
    // const url = `http://127.0.0.1:8017/api/history/list${queryString}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch room data");
    }
    const data = await response.json();
    return data;
};
  
const toQueryString = (params) => {
    const queryString = Object.keys(params)
      .map(key => {
        const value = params[key];
        return value !== undefined && value !== null && value !== "" ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : '';
      })
      .filter(Boolean)
      .join('&');
    
    return queryString ? `?${queryString}` : '';
};