import React, { useState } from 'react';
import RoomList from './RoomList';
import FloorMap from './FloorMap';
import Dropdown from "./BootstrapDropdown";

const FloorSelector = () => {
  const [selectedFloor, setSelectedFloor] = useState(0);
  const [selectedView, setSelectedView] = useState('roomList'); // 切換 view

  const handleFloorChange = (event) => {
    setSelectedFloor(event);
  };

  const handleViewChange = (event) => {
    setSelectedView(event);
    if (event === "floorMap" && selectedFloor === 0) {
        setSelectedFloor(1);
    } else if (event === "roomList") {
        setSelectedFloor(0);
    }
  };

  return (
    <div className="room-list__wrapper">
        <div className="room-list__selected_wrapper">
            {/* 切換顯示的視圖 */}
            <div className="room-list__selected">
                <Dropdown
                    options={[
                        { value: "roomList", label: "感測器列表" },
                        { value: "floorMap", label: "樓層平面圖" }
                    ]}
                    selected={selectedView}
                    onSelect={handleViewChange}
                />
            </div>

            {/* 樓層選擇下拉選單 */}
            <div className="room-list__selected">
                <Dropdown
                    options={[
                        { value: 0, label: '請選擇' },
                        { value: 1, label: '1 樓' },
                        { value: 2, label: '2 樓' },
                        { value: 3, label: '3 樓' },
                        { value: 4, label: '4 樓' }
                    ]}
                    selected={parseInt(selectedFloor)}
                    onSelect={handleFloorChange}
                />
            </div>
        </div>

        {/* 根據選擇的視圖顯示 RoomList 或 FloorMap */}
        <div style={{ marginTop: '20px' }}>
            {selectedView === 'roomList' ? (
            <RoomList floor={selectedFloor} />
            ) : (
            <FloorMap floor={selectedFloor} />
            )}
        </div>
    </div>
  );
};

export default FloorSelector;
