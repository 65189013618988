import React, { useEffect, useState } from 'react';
import useFetchHistoryData from '../hooks/useFetchHistoryData';
import { DataGrid } from '@mui/x-data-grid';

const History = () => {
    const [rows, setRows] = useState([]);
    const [floor, setFloor] = useState(0);
    const [mac, setMac] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const { history } = useFetchHistoryData(floor, mac, startTime, endTime);

    useEffect(() => {
        if (history) {
            setRows(history);
        }
    }, [history]);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 2 },
        { field: 'name', headerName: '名稱', flex: 1 },
        { field: 'mac', headerName: 'Mac', flex: 2 },
        { field: 't', headerName: '溫度', type: 'number', flex: 1 },
        { field: 'h', headerName: '濕度', type: 'number', flex: 1 },
        { field: 'date', headerName: '時間', flex: 2 },
    ];

    const paginationModel = { page: 0, pageSize: 20 };
    return (
        <div className="history_wrapper">
            <div className="search_wrapper">
                <div>
                    <select
                        id="floor"
                        value={floor}
                        onChange={(e) => setFloor(e.target.value)} // 用 setState 更新
                    >
                        <option value={0}>請選擇樓層</option>
                        <option value={1}>1 樓</option>
                        <option value={2}>2 樓</option>
                        <option value={3}>3 樓</option>
                        <option value={4}>4 樓</option>
                    </select>
                </div>
                <div>
                    <input
                        id="mac"
                        type="text"
                        placeholder="mac"
                        value={mac}
                        onChange={(e) => setMac(e.target.value)} // 用 setState 更新
                    />
                </div>
                <div>
                    <input
                        id="startTime"
                        type="datetime-local"
                        placeholder="開始時間"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)} // 用 setState 更新
                    />
                </div>
                <div>
                    <input
                        id="endTime"
                        type="datetime-local"
                        placeholder="結束時間"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)} // 用 setState 更新
                    />
                </div>
            </div>
            <div className='data_grid_wrapper'>
                <DataGrid 
                    className='data_grid'
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10, 15, 20]}
                    pageSize={15}
                />
            </div>
        </div>
    );
};

export default History;
