// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 響應式設計 */
@media (min-width: 768px) and (max-width: 1024px) {
    .room-list__wrapper {
        width: 100%;
    }
    .content {
        width: 100%;
    }
}

@media (max-width: 480px) and (max-width: 768px) {
    .room-list__wrapper {
        width: 100%;
    }
    .room-list__item {
        width: 80%;
    }
    .content {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .room-list__wrapper {
        width: 100%;
    }
    .room-list__item {
        width: 185px;
    }
    .content {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/rwd.css"],"names":[],"mappings":"AAAA,UAAU;AACV;IACI;QACI,WAAW;IACf;IACA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,UAAU;IACd;IACA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,YAAY;IAChB;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":["/* 響應式設計 */\n@media (min-width: 768px) and (max-width: 1024px) {\n    .room-list__wrapper {\n        width: 100%;\n    }\n    .content {\n        width: 100%;\n    }\n}\n\n@media (max-width: 480px) and (max-width: 768px) {\n    .room-list__wrapper {\n        width: 100%;\n    }\n    .room-list__item {\n        width: 80%;\n    }\n    .content {\n        width: 100%;\n    }\n}\n\n@media (max-width: 480px) {\n    .room-list__wrapper {\n        width: 100%;\n    }\n    .room-list__item {\n        width: 185px;\n    }\n    .content {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
