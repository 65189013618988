// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header_wrapper {
    height: 100px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
}

.header {
    width: 1200px;
    display: flex;
    justify-content: space-between;
}

.navigation {
    display: flex;
    align-items: center;
}

.navigation a {
    margin-right: 10px
}

.data_grid_wrapper {
    height: 600px;
    width: 100%;
}

.data_grid {
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,0CAA0C;IAC1C,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["\n.header_wrapper {\n    height: 100px;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);\n    display: flex;\n    justify-content: center;\n}\n\n.header {\n    width: 1200px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.navigation {\n    display: flex;\n    align-items: center;\n}\n\n.navigation a {\n    margin-right: 10px\n}\n\n.data_grid_wrapper {\n    height: 600px;\n    width: 100%;\n}\n\n.data_grid {\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
